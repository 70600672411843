
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary: #028e85;
  --background: #f5f8ff;
}

.primary {
  color: var(--primary);
}
  
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}   

a {
  text-decoration: none;
  color: #333;
}

.container {
  max-width: 100vw;
  margin: 0;
}

#tsparticles {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  z-index: -1;
}

body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  background-color: #f5f8ff;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* // fade footer code */

.rockbottom {
  bottom: 0;
  position:fixed;
}

.fade {
  margin: 0px;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  text-align: center;
  transition: opacity 0.4s ease-in;
}

.fadeOut { opacity: 0; }
.fadeIn { opacity: 1; }

h1 {
  font-size: 3.5rem;
  line-height: 1.2;
}

h2 {
  font-size: 2.4rem;
  line-height: 1.2;
}

h5 {
  font-size: 1.1rem;
}

p {
  font-size: 1.2rem;
}


.btn {
  padding: 5px 16px;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: #fff;
  border-radius: 8px 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.gray_sub_head_text {
  color: rgb(135, 135, 135);
}

.btn:hover {
  box-shadow: rgb(0 0 0 / 15%) 0px 8px 24px;
}

input {
  padding: 8px 6px;
  border: 1px solid var(--primary);
  background: transparent;
  border-radius: 24px 4px;
  font-size: 1.7rem;
  margin-right: .8rem;
  font-family: 'Poppins', sans-serif;
}

textarea {
  padding: 8px 6px;
  border: 1px solid var(--primary);
  background: transparent;
  border-radius: 24px 4px;
  font-size: 1.7rem;
  margin-right: .8rem;
  font-family: 'Poppins', sans-serif;
}

.centerpopup {
position: absolute;
width: 100vw;
margin: auto;
top: 50%;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  bottom: 0;
  background: #000000ba;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.bottom {
  justify-content: flex-end;
}

.top {
  justify-content: flex-start;
}

.container {
  display: flex;
  width: 100vw;
  height: 100%;
  margin: auto;
}

.modal {
  width: clamp(50%, 700px, 90%);
  height: min(50%, 300px);
  margin: auto;
  padding: 0 2rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-button {
  position: relative;
  bottom: 1.5rem;
  padding: 0 3rem;
  min-height: 3rem;
  margin: auto auto 0 auto;
  background: var(--dark);
  color: var(--light);
}

.save-button {
  padding: 0 1rem;
  margin: 2rem auto auto 0;
  background: var(--gradient);
  color: var(--dark);
}

.close {
  height: 1.1rem;
  background: transparent;
  border: none;
  outline: none;
  margin: 0 0 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.close-button {
  padding: 0 2rem;
  height: 2.5rem;
  margin: 2rem auto 1rem 0;
  background: #101111;
  /* border: 1px dashed #9a9a9a99; */
  color: #ffaa00;
  border-radius: 4px;
  transition: background ease 400ms;
  box-shadow: 1px 1px 15px #03030399;
}


@media screen and (max-width:768px) {
  h1 {
    font-size: 2.1rem;
    line-height: 1.2;
  }
  
  h2 {
    font-size: 1.5rem;
    line-height: 1.2;
  }
  
  h5 {
    font-size: 1.1rem;
    line-height: 1.2;
  }
  
  p {
    font-size: 1rem;
  }

  .btn {
    width: 100%;
    margin: 1rem 0;
  }

  input {
    width: 100%;
  }

}






