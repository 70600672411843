.featured {
    width: 100%;
    padding-bottom: 3rem;
}

.subheader {
  color:  #028e85;
  opacity: 85%;
}

.featured .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    padding: 0rem;
}

.featured .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navbarheight {
    height: 80px;
}

.graysmtext {
    color: #94A3B8;
    font-weight: 600;
    line-height: 1.2rem;
}
.blacksmtext {
    font-size: 19px;
    color: #334155;
    line-height: 1.7rem;
    font-weight: 600;
}
  
.valuetext {
   color: #94A3B8;
}

.featured .left p {
    margin: 1.3rem 0;
}

.featured .left .btn {
    align-self: flex-start;
}

.featured .right {
    display: flex;
    flex-wrap: wrap;
    max-width: 650px;
    justify-content:center;
}

.featured .right .card {
    display: flex;
    flex-direction: column;
   
    padding: 0px 7px;
    border-radius: 24px;
    box-shadow: rgb(0 0 0 / 12%) 0px 8px 24px;
    text-align: center;
}

.featured .right .card:hover {
    box-shadow: rgb(0 0 0 / 20%) 0px 8px 24px;
}

.featured .card .top {
    display: flex;
    flex-direction: column;
}

.featured .right img {
    width: 50px;
    margin: auto;
    padding: .2rem 0;
}

.featured .icon {
    font-size: 36px;
    margin: auto;
    padding-top: 6px;
    justify-content: center;
    align-items: center;
}

.featured span {
    font-size: 22px;
}

.green {
    color: var(--primary);
}

.red {
    color: #ff0000;
}

.delbtn {
    display: flex;
    justify-content: right;
}


 stuff from v3 :-moz-user-disabled

button {
  width: auto;
  height: 3rem;
  border: none;
  outline: none;
  -webkit-appearance: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1.25rem;
  letter-spacing: 1.25px;
  cursor: default;
  font-family: "Montserrat", sans-serif;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.modal {
  width: clamp(50%, 700px, 90%);
  height: min(50%, 300px);
  margin: auto;
  padding: 0 2rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orange-gradient {
  background: var(--gradient);
}

.green-gradient {
  background: var(--gradient2);
}

.pink {
  color: #c273ff;
}


.gray {
  color: #666666;
}

.light-blue {
  color: #00b7ff;
}

.modal-button {
  position: relative;
  bottom: 1.5rem;
  padding: 0 3rem;
  min-height: 3rem;
  margin: auto auto 0 auto;
  background: var(--dark);
  color: var(--light);
}

.save-button {
  padding: 0 1rem;
  margin: 2rem auto auto 0;
  background: var(--gradient);
  color: var(--dark);
}

.close-button {
  padding: 0 2rem;
  height: 2.5rem;
  margin: 2rem auto 1rem 0;
  background: #101111;
  /* border: 1px dashed #9a9a9a99; */
  color: #ffaa00;
  border-radius: 4px;
  transition: background ease 400ms;
  box-shadow: 1px 1px 15px #03030399;
}   

.mobilefooter  {
    background-color: white;
    width: 100vw;
    padding: 0px 0;
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    position: fixed;
    left: 0;
    bottom: 0;
}

@media screen and (max-width: 940px) {
    .featured .container {
        grid-template-columns: 1fr;
    }

    .featured .left .btn {
        align-self: center;
    }
}

@media screen and (max-width: 478px) {
    .featured .container {
         grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 347px) {
  .featured .right {
       grid-template-columns: 1fr;
  }
}

/* @media screen and (max-width: 478px) {
  .featured .container .right .card {
      width: 100%;
  }
} */